<template lang="pug">
include ../../../configs/template
div.col-12
  div.row
    h2.col-12 Переклад:
  div.row
    div.col-sm-12(v-if="documentInfo.created_at")
      +data-info('createDate','getDateFormat(documentInfo.created_at)')
    div.col-sm-12(v-if="documentInfo.number_document")
      +data-info('docNumber','documentInfo.number_document')
    div.col-sm-12(v-if="documentInfo.serial")
      +data-info('serial','documentInfo.serial')
    div.col-sm-12(v-if="documentInfo.registry_number")
      +data-info('registrationNumber','documentInfo.registry_number')
    div(v-if="fullNameUkr").col-sm-12
      +data-info('fullName','fullNameUkr')
    div(v-if="documentInfo.extent_ukr").col-sm-12
      +data-info('educationExtent','documentInfo.extent_ukr')
    div(v-if="documentInfo.name_nz_ukr").col-sm-12
      +data-info('institution','documentInfo.name_nz_ukr')
    div(v-if="documentInfo.name_nz_eng").col-sm-12
      +data-info('institutionEng','documentInfo.name_nz_eng')
    div(v-if="documentInfo.name_nz_abbr_ukr").col-sm-12
      +data-info('institutionAbbrUkr','documentInfo.name_nz_abbr_ukr')
    div(v-if="documentInfo.name_nz_abbr_eng").col-sm-12
      +data-info('institutionAbbrEng','documentInfo.name_nz_abbr_eng')
    div(v-if="documentInfo.qualification_ukr").col-sm-12
      +data-info('qualification','documentInfo.qualification_ukr')
    div(v-if="documentInfo.specialization_ukr").col-sm-12
      +data-info('specialization','documentInfo.specialization_ukr')
    div(v-if="documentInfo.speciality_ukr").col-sm-12
      +data-info('specialty','documentInfo.speciality_ukr')
    div(v-if="documentInfo.special_notes_ukr").col-sm-12
      +data-info('specialNotes','documentInfo.special_notes_ukr')
    div.col-sm-12(v-if="documentInfo.date_start_educ")
      +data-info('date_start_educ','getDateFormat(documentInfo.date_start_educ)')
    div.col-sm-12(v-if="documentInfo.date_issue_document")
      +data-info('dateIssue','getDateFormat(documentInfo.date_issue_document)')
    div.col-sm-12(v-if="documentInfo.date_end_educ")
      +data-info('date_end_educ','getDateFormat(documentInfo.date_end_educ)')
</template>
<script>
import { getDateFormat } from '@/mixins/main'
import { mapGetters } from 'vuex'
export default {
  props: { documentInfo: { type: Object, default: () => ({}) } },
  data () {
    return {
      getDateFormat

    }
  },
  computed: {
    fullNameUkr () {
      return (
        `${this.documentInfo?.last_name_ukr || ''} ${this.documentInfo?.first_name_ukr || ''} ${
          this.documentInfo?.middle_name_ukr || ''
        }` || ''
      )
    },
    ...mapGetters(['getDirectoryObject'])

  }
}
</script>
